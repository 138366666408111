<template>
  <v-container class="home pb-12">
    <v-row justify="space-evenly"  align-content="space-between">
      <v-col md="6" lg="5">
        <h2 class="text-primary text-left">Intro :</h2>
        <carte-profil :profil="profil"></carte-profil>
      </v-col>
      <v-col md="6" lg="7">
        <h2 class="text-primary text-left">{{ profil.headlineProjetPrincipal }}</h2>
        <carte-projet
            image-height="auto"
            :project="project"
            color="primary"
        >
        </carte-projet>
        <v-container>
          <v-row justify="center">
            <v-btn class="my-5" color="primary" to="/projects">Mes autres projets</v-btn>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <h2 class="mt-4 text-primary text-left">Documents :</h2>
    <v-row>
      <v-col
          v-for="(doc, i) in profil.documents" :key="i"
          lg="4" md="6" sm="6"
          cols="12"
      >
        <carte-document :doc="doc">{{ doc.name }}</carte-document>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import CarteProjet from "@/components/carte-projet.vue";
import {mapGetters} from "vuex";
import CarteDocument from "@/components/carte-document.vue";
import CarteProfil from "@/components/carte-profil.vue";

export default {
  name: 'HomeView',
  components: {
    CarteProfil,
    CarteDocument,
    CarteProjet
  },
  computed: {
    ...mapGetters({
      profil: "profil/getProfil",
      getProject: "projects/getProjectById",

    }),
    project() {
      return this.getProject(this.profil.projetMisEnAvant)
    },
  },
}
</script>

<style scoped>
</style>