import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "vuetify/dist/vuetify.min.css";
import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
      options: { customProperties: true },
      dark: {
        dark: true,
        variables: {}, // ✅ this property is required to avoid Vuetify crash
        colors: {
          // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
          background: '#1e1e2e',
          surface: '#313244',
          primary: '#cba6f7',
        },
      },
      light: {
        dark: false,
        variables: {}, // ✅ this property is required to avoid Vuetify crash
        colors: {
          // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
          background: '#eff1f5',
          surface: '#EBEDF2',
          primary: '#8839ef',
        },
      },
    }
  }
})

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount('#app')

