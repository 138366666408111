<template>
  <v-card variant="elevated" elevation="5" :color="histo.color">
    <v-card-title class="text-wrap">{{ histo.headline }}</v-card-title>
    <v-card-text>{{ histo.shortDesc }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          v-if="histo.live"
          target="_blank" :href="histo.live.link"
          variant="tonal" prepend-icon="mdi-web"
      >Site de l'etablissement</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "carte-historic",
  props: {
    histo: {
      type: Object,
      default: undefined
    },
    showImage: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>