export default {
    namespaced: true,
    state: {
        documents: [

        ]
    },
    getters: {
        getDocuments(state) {
            return state.documents;
        },
    },
    mutations:{
    },
    actions:{
    }
}