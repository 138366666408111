<template>
  <v-container class="container-competences">
    <h2>Conduire</h2>
    <h3>Appliquer une démarche de suivi de projet en fonction des besoins métiers des clients et des utilisateurs</h3>
    <v-row justify="start">
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[6]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[4]"></carte-projet>
      </v-col>
    </v-row>
    <v-divider class="my-12" thickness="6"></v-divider>

    <h2>Réaliser</h2>
    <h3>Adapter des applications sur un ensemble de supports</h3>
    <v-row justify="start">
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[6]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[2]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[3]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[5]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[4]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[1]"></carte-projet>
      </v-col>
    </v-row>
    <v-divider class="my-12" thickness="6"></v-divider>

    <h2>Collaborer</h2>
    <h3>Manager une équipe informatique</h3>
    <v-row justify="start">
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[2]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[3]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[4]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[1]"></carte-projet>
      </v-col>
    </v-row>
    <v-divider class="my-12" thickness="6"></v-divider>

    <h2>Optimiser</h2>
    <h3>Analyser et optimiser des applications</h3>
    <v-row justify="start">
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[0]"></carte-projet>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <carte-projet :project="projects[5]"></carte-projet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import CarteProjet from "@/components/carte-projet.vue";

export default {
  name: 'ProjectView',
  components: {CarteProjet},
  computed: {
    ...mapGetters({
      projects: "projects/getProjects"
    }),
  },
}
</script>

<style scoped>
.container-competences {
  justify-content: start;
}

h2, h3 {
  text-align: start;
}
p {
  padding-bottom: 6px;
}
</style>