<template>
  <v-card>
    <v-card-title>{{ competence.libelle }}</v-card-title>
    <v-card-subtitle>{{ competence.nom }}</v-card-subtitle>
    <v-card-text class="text-justify">{{ competence.justification }}</v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "carte-competence",
  props: {
    competence: {
      type: Object
    },
  },
}
</script>

<style lang="scss" scoped>

</style>