<template>
  <v-card width="100%" variant="outlined" color="primary">
    <v-card-title>
      <v-container>
        <v-row justify="center" align-content="center">
          <v-icon class="mr-2" color="primary" icon="mdi-file-document" :size="size"></v-icon>
        </v-row>
        <v-row justify="center">
          <slot></slot>
        </v-row>
      </v-container>
    </v-card-title>
    <v-btn density="compact" variant="tonal" block="" target="_blank" :href="docLink" color="primary" prepend-icon="mdi-eye">Visionner</v-btn>
  </v-card>
</template>

<script>
export default {

  name: "carte-document",
  props: {
    doc: {
      type: Object,
    },
    size: {
      type: String,
      default: "50px"
    },
  },
  computed: {
    docLink() {
      return process.env.VUE_APP_URL_DOCUMENTS+this.doc.file
    }
  },
}
</script>