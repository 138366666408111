<template>
  <v-container class="pa-10">
    <v-row>
      <v-col cols="6">
        <carte-projet :project="projects[3]"></carte-projet>
      </v-col>
      <v-col cols="6">
        <carte-projet :project="projects[2]"></carte-projet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <carte-projet :show-image="false" :project="projects[1]"></carte-projet>
      </v-col>
      <v-col cols="4">
        <carte-projet :show-image="false" :project="projects[4]"></carte-projet>
      </v-col>
      <v-col cols="4">
        <carte-projet :show-image="false" :project="projects[0]"></carte-projet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <carte-projet :show-image="true" :project="projects[5]"></carte-projet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarteProjet from "@/components/carte-projet.vue";
import {mapGetters} from "vuex";

export default {
  name: 'TestView',
  components: {
    CarteProjet
  },
  computed: {
    ...mapGetters({
      projects: "projects/getProjects"
    }),
  },
}
</script>
