<template>
  <v-app-bar app>
    <v-app-bar-nav-icon
        @click="drawer = !drawer">
    </v-app-bar-nav-icon>
    <h1 class="text-left text-no-wrap">{{ profil.titre }} </h1>
    <v-spacer></v-spacer>
    <router-link class="rl hidden-sm-and-down" to="/"><v-btn variant="flat" color="primary">Mon profil</v-btn></router-link>
    <router-link class="rl hidden-sm-and-down" to="/projects"><v-btn variant="flat" color="primary">Mes projets</v-btn></router-link>
    <router-link class="rl hidden-sm-and-down" to="/competences"><v-btn variant="flat" color="primary">Mes competences</v-btn></router-link>
    <router-link class="rl hidden-sm-and-down" to="/historique"><v-btn variant="flat" color="primary">Mon parcours</v-btn></router-link>
    <v-app-bar-nav-icon
        icon="mdi-theme-light-dark"
        @click="toggleTheme"
    ></v-app-bar-nav-icon>
  </v-app-bar>
  <v-navigation-drawer
      v-model="drawer"
      temporary=""
      class="pa-0"
  >
    <v-list>
      <v-list-item>
        <router-link to="/"><v-btn text="" border width="100%" color="primary">Mon profil</v-btn></router-link>
      </v-list-item>
      <v-list-item>
        <router-link to="/projects"><v-btn text="" border width="100%" color="primary">Mes projets</v-btn></router-link>
      </v-list-item>
      <v-list-item>
        <router-link to="/competences"><v-btn text="" border width="100%" color="primary">Mes competences</v-btn></router-link>
      </v-list-item>
      <v-list-item>
        <router-link to="/historique"><v-btn text="" border width="100%" color="primary">Mon parcours</v-btn></router-link>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "barre-navigation",
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    ...mapGetters ({
      profil: "profil/getProfil"
    })
  }
};
</script>
<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
}
</script>
<style scoped>
.header-container {
  width: 100%;
  max-width: 100%;
}
h1 {
  text-wrap: none;
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  h1 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  h1 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1424px) {
  h1 {
    font-size: 42px;
  }
}
@media only screen and (min-width: 1424px) {
  h1 {
    font-size: 48px;
  }
}
.rl {
  padding-right: 5px;
}
</style>