<template>
  <v-container>
    <h1>Mon parcours</h1>
    <v-container class="pa-0 d-none d-md-flex">
      <v-timeline
          direction="vertical"
          side="end"
      >
        <v-timeline-item
            v-for="(histo, i ) in historique" :key="i"
            :dot-color="histo.color"
            :icon="histo.icon"
            size="x-large"
        >
          <template #opposite>
            {{ histo.date }}
          </template>
          <carte-historic
              :show-image="false"
              :histo="histo"
          >
          </carte-historic>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <v-container class="pa-0 d-md-none">
      <v-row
          justify="center"
          v-for="(histo, i ) in historique" :key="i"
      >
        <v-col>
          <v-label>{{ histo.date }}</v-label>
          <carte-historic
              class="mx-3"
              :dot-color="histo.color"
              :show-image="false"
              :histo="histo"
          >
          </carte-historic>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import CarteProjet from "@/components/carte-projet.vue";
import {mapGetters} from "vuex";
import CarteHistoric from "@/components/carte-historic.vue";

export default {
  components: {CarteHistoric, CarteProjet},
  computed: {
    ...mapGetters({
      historique: "historic/getProjects"
    })
  },

}
</script>

<style scoped>
</style>