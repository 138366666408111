export default {
    namespaced: true,
    state: {
        historique: [
            {
                "ordre": 0,
                "date": "2015 - 2017",
                "headline": "Collège Sainte Marie à Ambérieu-en-Bugey - 01500",
                "shortDesc": "J'ai passé mes trois premières années de collège dans un établissement privé.",
                "color":"#cba6f7",
                "icon":"mdi-school",
                "live": {
                    "type": "WEBSITE",
                    "link": "https://saintemarieamberieu.fr/"
                },
            },
            {
                "ordre": 1,
                "date": "2017 - 2018",
                "headline": "Collège Nelson Mandela à Noé - 31410",
                "shortDesc": "Suite à un déménagement, je suis allé collège à coté de chez moi pour y passer mon brevet.",
                "color":"#ef9f76",
                "icon":"mdi-school",
                "live": {
                    "type": "WEBSITE",
                    "link": "https://nelson-mandela.ecollege.haute-garonne.fr/"
                },
            },
            {
                "ordre": 2,
                "date": "2018 - 2021",
                "headline": "Lycée Déodat de Séverac à Toulouse - 31000",
                "shortDesc": "J'ai choisi d'aller en internat dans un lycée technique à Toulouse. Après la seconde générale, je me suis engagé dans la filière technologique STI2D avec la spécialitée Système d'Information et Numérique.",
                "color":"#209fb5",
                "icon":"mdi-school",
                "live": {
                    "type": "WEBSITE",
                    "link": "https://deodat.mon-ent-occitanie.fr/"
                },
            },
            {
                "ordre": 3,
                "isDiplome": true,
                "date": "2021",
                "headline": "Obtention du baccalauréat avec mention bien !",
                "shortDesc": "J'ai obtenu mon BAC STI2D - SIN avec mention",
                "color":"#ed8796",
                "icon":"mdi-party-popper",
            },
            {
                "ordre": 4,
                "date": "2021 - 2023",
                "headline": "Université Paul Sabatier à Toulouse - 31000",
                "shortDesc": "Je poursuis mes études dans l'université TLSE-3 afin d'y obtenir mon Diplôme Universitaire Technologique en Informatique.",
                "color":"#a6d189",
                "icon":"mdi-school",
                "live": {
                    "type": "WEBSITE",
                    "link": "https://iut.univ-tlse3.fr/"
                },
            },
            {
                "ordre": 5,
                "isDiplome": true,
                "date": "2023",
                "headline": "Obtention du DUT",
                "shortDesc": "Le DUT est l'ancien diplôme qui est à présent remplacé par le BUT. Ce dernier est obtenable en trois années d'étude mais le premier est toujours distribué au bout de 2 ans.",
                "color":"#89b4fa",
                "icon":"mdi-party-popper",
            },
            {
                "ordre": 6,
                "date": "2023 - 2024",
                "headline": "Poursuite vers un bachelor",
                "shortDesc": "Après avoir obtenu mon DUT, je décide de poursuivre mes études dans la même université afin d'obtenir mon Bachelor.",
                "color":"#e5c890",
                "icon":"mdi-school",
                "live": {
                    "type": "WEBSITE",
                    "link": "https://iut.univ-tlse3.fr/"
                },
            },
            {
                "ordre": 7,
                "isDiplome": true,
                "date": "2024",
                "headline": "Obtention du BUT Informatique",
                "shortDesc": "Un an après mon DUT, j'ai obtenu le BUT.",
                "color":"#ed8796",
                "icon":"mdi-party-popper",
            },
            {
                "ordre": 8,
                "date": "2024 - 2027",
                "headline": "Cursus en école d'ingénieur",
                "shortDesc": "Mon BUT en poche, je complète actuellement mes connaissances théoriques dans la promotion R&I à l'école d'ingénieur INP ENSEIRB-MATMECA de Bordeaux.",
                "color":"#ef9f76",
                "icon":"mdi-school",
                "live": {
                    "type": "WEBSITE",
                    "link": "https://enseirb-matmeca.bordeaux-inp.fr/fr"
                },
            },
        ]
    },
    getters: {
        getProjects(state) {
            return state.historique.sort((p1, p2) => p2.ordre-p1.ordre);
        },

    },
    mutations:{
    },
    actions:{
    }
}
