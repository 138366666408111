<template>
    <v-container>
      <v-row>
      <h2><slot></slot></h2>
      </v-row>
      <v-container class="">
        <v-row justify="space-evenly">
          <v-col
              sm="12"
              md="6"
              lg="4"
              v-for="(img, i) in images" :key="i"
          >
            <v-card
                max-height="40vh"
                @click="showImg(i)"
                :elevation="12"
            >
              <v-img :src="img.src" :alt="img.title"></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
</template>

<script>
export default {
  name: "image-galerie",
  props: {
    images: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      index: 0,
      visible: false,
    }
  },
  methods: {
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    }
  }
}
</script>

<style scoped>
.lgt {
  z-index: 99999999999999;
}

.v-card--reveal {
  margin: 5px;
  align-items: end;
  bottom: 0;
  justify-content: end;
  opacity: .85;
  position: absolute;
}
</style>

