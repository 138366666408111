import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjetsView from "@/views/ProjetsView.vue";
import TestView from "@/views/TestView.vue";
import TimelineView from "@/views/TimelineView.vue";
import DetailProjetView from "@/views/DetailProjetView.vue";
import CompetencesView from "@/views/CompetencesView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/competences',
    name: 'competences',
    component: CompetencesView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjetsView
  },
  {
    path: '/project/:projectId',
    name: 'project',
    props: true,
    component: DetailProjetView
  },
  {
    path: '/historique',
    name: 'historique',
    component: TimelineView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
