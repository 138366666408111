<template>
  <div class="text-left text-justify" v-html="convert">
  </div>
</template>

<script>
import markdownit from "markdown-it"

export default {
  props: {
    mdText: {
      type: String,
      default: ""
    },
  },
  computed: {
    convert() {
      let md = markdownit("commonmark", {
        breaks: true,
      });
      return md.renderInline(this.mdText);
    }
  },
}
</script>

<style lang="scss" scoped>

</style>