<template>
  <v-card
      color="surface"
      variant="elevated"
      elevation="6"
      class="carte pa-6"
    :color="project.color"
  >
    <v-card-title>
      <h1>{{ project.titre }}</h1>
    </v-card-title>
    <v-card-subtitle>
        {{ project.headline }}
    </v-card-subtitle>
    <v-card-text>{{ project.shortDesc }}</v-card-text>
    <v-divider class="ma-2" thickness="4" color="black"></v-divider>
    <v-card-text class="text-left">
      <v-container>
        <h2>Description</h2>
      </v-container>
      <v-container>
        <to-markdown :md-text="project.texte.texte"></to-markdown>
      </v-container>
    </v-card-text>
    <v-divider
        class="ma-2" thickness="4" color="black"
        v-if="project.competences && project.competences.length > 0"
    ></v-divider>
    <v-card-text v-if="project.competences && project.competences.length > 0" class="text-left">
      <v-container>
        <h2>Point de vue compétences</h2>
      </v-container>
      <v-container>
        <v-row>
          <v-col v-for="(comp, i) in project.competences" :key="i">
            <carte-competence color="primary" :competence="comp"></carte-competence>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider
        class="ma-2" thickness="4" color="black"
        v-if="project.images && project.images.length > 0"
    ></v-divider>
    <image-galerie
        v-if="project.images && project.images.length > 0"
        :images="project.images"
    >Quelques visuels</image-galerie>
    <v-divider class="ma-2" thickness="4" color="black"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          v-if="project.source"
          target="_blank" :href="project.source.link"
          variant="tonal" prepend-icon="mdi-gitlab"
      >Dépôt source</v-btn>
      <v-btn
          v-if="project.live"
          target="_blank" :href="project.live.link"
          variant="tonal" prepend-icon="mdi-web"
      >Lien du site</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import CarteCompetence from "@/components/carte-competence.vue";
import ImageGalerie from "@/components/galerie-image.vue";
import ToMarkdown from "@/components/to-markdown.vue";

export default {
  name: "detail-projet",
  components: {ToMarkdown, ImageGalerie, CarteCompetence},
  props: {
    projectId: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      getProject: "projects/getProjectById"
    }),
    project() {
      return this.getProject(this.projectId)
    },
  },
}
</script>

<style scoped>

@media only screen and (max-width: 767px) {
  .carte {
    margin: 0;
  }
  div {
    font-size: small;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .carte {
    margin: 5vh 5%;
  }
  div {
    font-size: medium;
  }
}
@media only screen and (min-width: 1024px) {
  .carte {
    margin: 5vh 15%;
  }
  div {
    font-size: large;
  }
}
</style>