import { createStore } from 'vuex'
import profil from "@/store/modules/profil";
import projects from "@/store/modules/projects";
import documents from "@/store/modules/documents";
import historic from "@/store/modules/historic";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    profil,
    projects,
    documents,
    historic
  }
})
