<template>
  <v-card
      variant="elevated"
      elevation="6"
      :color="color ? color : project.color"
  >
    <v-img class="ma-2 rounded" v-if="showImage && project.imagePrincipale" cover="" max-height="40vh" :aspect-ratio="24/9" :src="project.imagePrincipale" ></v-img>
    <v-card-title>{{ project.titre }}</v-card-title>
    <v-card-subtitle>{{ project.headline }}</v-card-subtitle>
    <v-chip
        class="ma-1"
        v-for="(lang, i) in project.langages" :key="i"
        :href="lang.link"
        target="_blank"
    >{{ lang.lang }}</v-chip>
    <v-card-text class="text-justify">
      {{ project.shortDesc }}
    </v-card-text>
    <v-card-actions class="actions bg-surface ma-1">
      <v-row justify="center" class="ma-0">
        <v-col class="pa-1">
          <v-btn
              :to="'/project/'+project.name"
              variant="flat" :color="color ? color : project.color"
              prepend-icon="mdi-eye"
          >En savoir plus !</v-btn>
        </v-col>
        <v-col class="pa-1" v-if="project.source">
          <v-btn
              target="_blank" :href="project.source.link"
              variant="tonal" prepend-icon="mdi-gitlab"
          >Dépôt source</v-btn>
        </v-col>
        <v-col class="pa-1"  v-if="project.live">
          <v-btn
              target="_blank" :href="project.live.link"
              variant="tonal" prepend-icon="mdi-web"
          >Lien du site</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "carte-projet",
  props: {
    project: {
      type: Object,
      default: undefined
    },
    showImage: {
      type: Boolean,
      default: true
    },
    imageHeight: {
      type: String,
      default: "auto"
    },
    color: {
      type: String,
      default: undefined
    }
  },
}
</script>

<style scoped>
.actions {
  bottom: 2px;
  border-radius: 8px;
}
</style>