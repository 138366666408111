<template>
  <v-container>
    <v-row justify="space-evenly">
      <v-col md="6">
        <carte-projet :project="projects[7]"></carte-projet>
      </v-col>
      <v-col md="6">
        <carte-projet :project="projects[2]"></carte-projet>
      </v-col>
      <v-col md="4">
        <carte-projet :show-image="false" :project="projects[1]"></carte-projet>
      </v-col>
      <v-col md="4">
        <carte-projet :show-image="false" :project="projects[4]"></carte-projet>
      </v-col>
      <v-col md="4">
        <carte-projet :show-image="false" :project="projects[0]"></carte-projet>
      </v-col>
      <v-col md="7">
        <carte-projet :show-image="true" :project="projects[5]"></carte-projet>
      </v-col>
      <v-col md="5">
      <carte-projet :show-image="true" :project="projects[6]"></carte-projet>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import CarteProjet from "@/components/carte-projet.vue";

export default {
  name: 'ProjectView',
  components: {CarteProjet},
  computed: {
    ...mapGetters({
      projects: "projects/getProjects"
    }),
  },
}
</script>

